export class Insumo {
    constructor(
        public _id: String,
        public materia:String,
        public periodo:String,
           
        public DescAsistencia1:String,
        public DescAsistencia2:String,
        public DescAsistencia3:String,
        public DescAsistencia4:String,
        public DescAsistencia5:String,
        public DescAsistencia6:String,
        public DescAsistencia7:String,
        public DescAsistencia8:String,

        public DescTarea1:String,
        public DescTarea2:String,
        public DescTarea3:String,
        public DescTarea4:String,
        public DescProyecto1:String,

        public DescTarea11:String,
        public DescTarea22:String,
        public DescTarea33:String,
        public DescTarea44:String,
        public DescProyecto2:String,
     
    
    ) { }
}



