export class Calculable {
    constructor(

        public PromedioAsistencia:Number,
        public treintaycincoporcientotareas1:Number,
        public treintaporcientoproyecto1:Number,
        public treintaycincoporcientoexamen1:Number,
        public totalparcial1:Number,
        
        public treintaycincoporcientotareas2:Number,
        public treintaporcientoproyecto2:Number,
        public treintaycincoporcientoexamen2:Number,
        public totalparcial2:Number,
        
        public promedioPeriodo:Number,
        public EstadoNotas:string,
        

    ) { }
}

