import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InscripcionService {

  public identity;
  constructor() { }


  getIdentity() {
    let identity = JSON.parse(localStorage.getItem("identityInscripcion"));
    if (identity != "undefined") {
      this.identity = identity;
    } else {
      this.identity = null;
    }
    return this.identity;
  }

}
