import { Component, OnInit } from '@angular/core';
import { AdministradorService } from "../app/services/administrador.services";
import { DocenteService } from "../app/services/docente.services";
import { EstudianteService } from "../app/services/estudiante.services";
import { InscripcionService } from './services/inscripcion.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'plataforma';

  rootPage: any;


  public token;
  public identityAdmin;
  public identityEstudiante;
  public identityDocente;
  public identityInscripcion;
  public bander = true;
  constructor(private _adminService: AdministradorService, private _docenteService: DocenteService, private _estudianteService:EstudianteService, private _inscripcionService:InscripcionService) {

   

  }

  ngOnInit() {


    this.identityDocente = this._docenteService.getIdentity();
    this.token = this._docenteService.getToken();

    this.identityAdmin = this._adminService.getIdentity();
    this.token = this._adminService.getToken();

    this.identityEstudiante = this._estudianteService.getIdentity();
    this.token = this._estudianteService.getToken();

    this.identityInscripcion = this._inscripcionService.getIdentity();

    if (this.identityAdmin || this.identityDocente || this.identityEstudiante ||  this.identityInscripcion) {
      this.bander = false;
    }

    if(this.identityInscripcion="true"){
      localStorage.setItem("identityInscripcion","false");
    }


    console.log("las vaibles del Storage");
    console.log(this.identityAdmin + this.token);


  }


}

